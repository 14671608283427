import { StringMap, TFunction, TOptions } from 'i18next';
import { setLocale } from 'yup';

interface TranslatedMessageParams {
  translationKey: string;
}

export const configureYup = (t: TFunction) => {
  /**
   * Resolves first existing translation by following priorities:
   * 1. validation:byField.<fieldName>.<validationType>;
   * 2. validation:byField.<fieldName>;
   * 3. validation:<validationType>
   */
  const getFieldTranslation = (fieldName: string, validationType?: string, params?: TOptions<StringMap>) => {
    const fallbackTranslation = () => (validationType ? t(`validation:${validationType}`, params) : undefined);

    const translation = t(`validation:byField.${fieldName}`, fallbackTranslation && fallbackTranslation(), params);
    return typeof translation === 'object'
      ? validationType
        ? translation[validationType]
        : 'translation misconfiguration'
      : translation;
  };

  setLocale({
    mixed: {
      required: t('validation:required'),
      default: (val: Partial<TranslatedMessageParams>) => t(`validation:custom.${val.translationKey}`),
      oneOf: val => {
        //for checkboxes, always display 'required' validation, to avoid manually adding translations for each checkbox
        const isPresumablyCheckbox = typeof val.value === 'boolean';
        return isPresumablyCheckbox ? t(`validation:required`) : getFieldTranslation(val.path!);
      },
      notType: type => t('validation:notType', type),
    },
    string: {
      matches: val => getFieldTranslation(val.path!, 'matches', { regex: val.regex }),
      email: t('validation:email'),
      length: val => getFieldTranslation(val.path!, 'length', { length: val.length }),
      max: max => t('validation:maxString', max),
    },
    number: {
      min: min => t('validation:minNumber', min),
      max: max => t('validation:maxNumber', max),
    },
    array: {
      min: t('validation:required'),
    },
  });
};
