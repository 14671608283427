import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mdn-polyfills/Node.prototype.prepend';

import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';

import { configureI18n } from '@efi/fatca-shared/dist/i18n/i18n';
import { configureSentry } from './app/core/config/sentry';
import { configureYup } from './app/core/config/yup';
import Root from './app/Root';

(async () => {
  configureSentry();
  const t = await configureI18n(
    i18next,
    initReactI18next,
    {
      // react: {
      // wait: true,
      // },
      debug: !process.env.JEST_WORKER_ID && process.env.NODE_ENV !== 'production',
    },
    require(`@efi/fatca-shared/dist/i18n/overrides/${process.env.REACT_APP_ENVIRONMENT}/`)?.default ?? {},
  );
  configureYup(t);

  ReactDOM.render(<Root />, document.getElementById('root'));
})();
