import * as Sentry from '@sentry/browser';
import { isChunkLoadError, isKnownApiError, isTrueError } from '../utils/error.utils';

const isKnownError = (error: any) => {
  return isKnownApiError(error) || isChunkLoadError(error) || isTrueError(error);
};

export const configureSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.ENVIRONMENT,
      beforeSend: (event, hint) => {
        return !isKnownError(hint?.originalException) ? event : null;
      },
    });
  }
};
