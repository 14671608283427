import * as React from 'react';
import { CenteredSpin } from './shared/Layout/LoaderPresets';

const LazyApp = React.lazy(() => import('./App'));

const Root: React.FC = () => {
  return (
    <React.Suspense fallback={<CenteredSpin />}>
      <LazyApp />
    </React.Suspense>
  );
};

export default Root;
