import * as React from 'react';
import { Spin } from 'antd';
import { CenteredOverlay } from '../CenteredOverlay';
import styled from 'styled-components';

export const CenteredSpin: React.FC = () => {
  return (
    <CenteredOverlay>
      <Spin size="large" />
    </CenteredOverlay>
  );
};

const StyledSpin = styled(Spin)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
export const SimpleSpin: React.FC = () => {
  return <StyledSpin size="large" />;
};
