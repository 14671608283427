import * as HttpStatusCodes from 'http-status-codes';
import { ValidationError } from 'yup';
import { notification } from 'antd';
import { notificationConfig } from '../config/notification';
import { TFunction } from 'i18next';

export const isKnownApiError = (error: any): boolean => {
  if (typeof error !== 'object') {
    return false;
  } else {
    return error && error.status && error.status !== HttpStatusCodes.INTERNAL_SERVER_ERROR && 'statusText' in error;
  }
};

/**
 * May be used as a silent error as a convention (though must be avoided): Promise.reject(true)
 */
export const isTrueError = (error: any): boolean => {
  return error === true;
};

export const isChunkLoadError = (error: any): boolean => {
  if (!error || typeof error !== 'object') {
    return false;
  } else {
    const jsChunkLoadError = 'name' in error && error.name === 'ChunkLoadError';
    const cssChunkLoadError = 'code' in error && error.code === 'CSS_CHUNK_LOAD_FAILED';

    return jsChunkLoadError || cssChunkLoadError;
  }
};

export const isValidationError = (error: any): boolean => {
  return (
    typeof error === 'object' &&
    'data' in error &&
    'errorCode' in error.data &&
    error.data.errorCode === 'VALIDATION_ERROR'
  );
};

export const handleValidationError = (error: any, t: TFunction, translationKey: string) => {
  const validationErrors: ValidationError[] = error.data.errorMessage;

  validationErrors.forEach(e => {
    notification.error({
      ...notificationConfig,
      message: t(`${translationKey}_${e.path}_${e.type || ''}`, e),
    });
  });
};
